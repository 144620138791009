html,
body {
  height: 100%;
}

body {
  background: #fff url(./body-bg.svg) center center repeat;
  background-attachment: fixed;
  background-size: contain;
}

* {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* only inputs and CK editor are selectable */
input,
select,
textarea,
[contenteditable='true'],
[contenteditable='true'] * {
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
}

*:focus {
  outline: none;
}

.sentry-error-embed-wrapper {
  z-index: 1300 !important;
}

.root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pointer {
  cursor: pointer;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-wrap {
  word-break: break-all;
}

.no-underline {
  text-decoration: none;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-nowrap {
  white-space: nowrap;
}

a {
  text-decoration: none;
}

.d-block {
  display: block;
}

.d-none {
  display: none;
}

.full-size {
  width: 100%;
  height: 100%;
}

.d-flex {
  display: flex;
}

.d-flex.d-none {
  display: none;
}

.d-flex.column {
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.align-items-end {
  align-items: end;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.align-start {
  align-items: flex-start;
}

.justify-center {
  justify-content: center;
}

.d-flex.column {
  flex-direction: column;
}

.d-flex.nowrap {
  flex-wrap: nowrap;
}

.d-flex.wrap {
  flex-wrap: wrap;
}

.flex-1 {
  flex: 1 1 auto;
}

.order-first {
  order: -1;
}

.order-last {
  order: 1;
}

.sprite-svg {
  vertical-align: top;
}

.sprite-svg.extra-small {
  width: 12px;
  height: 12px;
  min-width: 12px;
  min-height: 12px;
}

.sprite-svg.small {
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
}

.sprite-svg.medium {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
}

.sprite-svg.medium-large {
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
}

.sprite-svg.large {
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
}

.sprite-svg.extra-large {
  width: 54px;
  height: 54px;
  min-width: 54px;
  min-height: 54px;
}

.text-wrap {
  word-break: break-word;
}

.img-with-preloader {
  width: 100%;
  height: 100%;
}

.img-with-preloader img {
  vertical-align: top;
  opacity: 0;
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.img-with-preloader .progress-bar {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  opacity: 1;
  max-width: 50%;
}

.img-with-preloader.loaded img {
  opacity: 1;
}

.img-with-preloader.loaded .progress-bar {
  opacity: 0;
}

.img-with-preloader img,
.img-with-preloader .progress-bar {
  transition: opacity 0.2s linear;
}

.profile-img {
  position: relative;
  min-height: 138px;
  min-width: 138px;
}

.profile-img img {
  width: 100%;
  height: 100%;
  text-align: center;
  object-fit: cover;
}

.profile-img.autoSize {
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
}

.profile-img.auto-size {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: center;
}

.profile-img.auto-size img {
  height: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.goog-te-banner-frame.skiptranslate,
.goog-tooltip.skiptranslate {
  display: none !important;
}

.full-screen-preloader,
.outline-none {
  outline: none;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.overflow-hidden {
  overflow: hidden;
}

.w-100 {
  width: 100%;
}

.__jivoMobileButton {
  display: none !important;
}

.visibility-hidden {
  visibility: hidden;
}

.semibold {
  font-weight: 600;
}

.bold {
  font-weight: bold;
}

.gap {
  --gap: 4px;
  margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
  width: calc(100% + var(--gap));
}

.gap > * {
  margin: var(--gap) 0 0 var(--gap);
}

.phoneCodeInput div {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.phoneNumberInput div {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  display: flex;
  height: 100%;
}

kbd {
  background: rgba(64, 0, 0, 0.1);
  border-radius: 4px;
  padding: 2px 4px;
  margin: 0 2px;
  border: 1px solid rgba(255, 0, 0, 0.1);
  font-weight: bold;
  color: #330000;
}

.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
}

.htmlNoOverscroll {
  overflow: hidden;
}

.bodyNoOverscroll {
  overflow: auto;
}

.alice-carousel {
  position: relative;
  width: 100%;
  height: 100%;
  margin: auto;
  direction: ltr;
}

.alice-carousel > div {
  height: 100%;
}

.alice-carousel__wrapper {
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.alice-carousel__stage {
  position: relative;
  display: flex;
  align-items: stretch;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  will-change: transform;
  transform-style: flat;
  -webkit-transform-style: flat;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

.alice-carousel__stage-item {
  position: relative;
  display: inline-block;
  flex-shrink: 0;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  vertical-align: top;
}
